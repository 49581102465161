// Desc: Fetches news from the backend.
import { setHost } from "./setHost.js";
const host = setHost(true);

export const newsFetcher = async (locale='en') => {
    const response = await fetch(
      host + "/api/news-collections/?populate=deep&sort=created:desc&locale=" + locale,
    );
    const data = await response.json();
    return data;
};

