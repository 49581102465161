// import { useState,useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import "swiper/swiper-bundle.css";
import { Button } from "./../../Elements/Button/Button.jsx";
import css from "./MoreNewsBlock.module.scss";
import { newsFetcher } from "../../../util/newsFetcher.js";
import { useState, useEffect } from "react";
import { BackgroundColorOptions } from "./../../Elements/BackgroundColorOptions/BackgroundColorOptions.jsx";

import { setHost } from "../../../util/setHost.js";
import { NewsCard } from "./NewsCard.jsx";
const host = setHost();

export const MoreNews = ({
  headerText,
  button,
  newsItems,
  backgroundColor,
  locale,
}) => {
  const [news, setNews] = useState(newsItems);

  const backgroundOptionColor = BackgroundColorOptions({
    optionColor: backgroundColor?.colors,
  });

  useEffect(() => {
    async function fetchNews() {
      const newsData = await newsFetcher(locale);
      setNews(newsData);
    }

    if (!news) {
      fetchNews();
    }
  }, [news]);

  function dateFormat(inputDate) {
    const date = new Date(inputDate);
    const year = date.getFullYear();
    const month = date.toLocaleString("sv", { month: "long" });
    const day = date.getDate();
    const formattedDate = day + " " + month + " " + year;
    return formattedDate;
  }

  return (
    <div className={`${css.newsLoop} ${backgroundOptionColor} wrapped full`}>
      <div className="uiRenderer">
        <div className="wrapped">
          <div className={css.titleNavWrapper}>
            <h2 className={css.title}>{headerText}</h2>

            <div className={css.navButtons}>
              <div className={`${css.swiperButtonNext} swiperjs-next`} />
              <div className={`${css.swiperButtonPrev} swiperjs-prev`} />
            </div>
          </div>

          <div className={css.swiperWrapper}>
            <Swiper
              modules={[Navigation]}
              slidesPerView={1}
              spaceBetween={16}
              breakpoints={{
                320: {
                  slidesPerView: 1,
                  spaceBetween: 20,
                },
                768: {
                  slidesPerView: 2,
                  spaceBetween: 40,
                },
                1024: {
                  slidesPerView: 3,
                  spaceBetween: 50,
                },
              }}
              navigation={{
                nextEl: ".swiperjs-next",
                prevEl: ".swiperjs-prev",
              }}
              pagination={{
                el: ".swiperPagination",
                clickable: true,
              }}
            >
              {news?.data?.map((item, index) => (
                <SwiperSlide className={css.swiperSlider} key={index}>
                  <NewsCard newsArticle={item} locale={locale} backendHost={host}/>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
          <div className={css.buttonWrapper}>
            <Button {...button} />
          </div>
        </div>
      </div>
    </div>
  );
};
